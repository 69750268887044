<template>
  <div class="app-container">
    <CrudTable entity="Language" :columns="columns" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [{ field: 'id', label: 'language.code' }, 'description', 'position']
    };
  }
};
</script>

<style scoped></style>
